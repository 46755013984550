var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.priceQuotationDetailsData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("PriceQuotationDetails.code")))]),_c('th',[_vm._v(_vm._s(_vm.$t("PriceQuotations.details.AffiliateOperation")))]),_c('th',[_vm._v(_vm._s(_vm.$t("StoreItems.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("PriceQuotations.code")))]),_c('th',[_vm._v(_vm._s(_vm.$t("PriceQuotations.clientName")))]),_vm._m(0)])]),_c('tbody',_vm._l((_vm.priceQuotationDetailsData),function(priceQuotationDetail,index){return _c('tr',{key:index},[_c('td',{class:priceQuotationDetail.canMoveToWorkProsess ? '' : 'bg-red'},[_vm._v(" "+_vm._s(++index + _vm.filterData.currentIndex)+" ")]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(priceQuotationDetail.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( priceQuotationDetail.affiliateOperationTypeNameCurrent ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( priceQuotationDetail.storeItemInfoData.storeItemNameCurrent ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(priceQuotationDetail.priceQuotationInfoData.fullCode))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( priceQuotationDetail.priceQuotationInfoData.requestPlaceInfoData .placeNameCurrent ))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(
                _vm.checkPrivilege(_vm.hasPriceQuotationAffiliateOperation()) &&
                priceQuotationDetail.quotationDetailsHistoryWorkTypeToken ==
                  _vm.QUOTATION_DETAILS_HISTORY_WORK_TYPES.WorkProgres
              )?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'PriceQuotationAffiliateOperations',
                  params: {
                    priceQuotationDetailsToken:
                      priceQuotationDetail.priceQuotationDetailsToken,
                  },
                },"title":_vm.$t('PriceQuotationAffiliateOperations.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/PriceQuotationAffiliateOperations.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setPriceQuotationDetailData(priceQuotationDetail);
                  _vm.openBottomSheet('PriceQuotationDetailInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),(priceQuotationDetail.canMoveToWorkProsess)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.PriceQuotationDetailMove",modifiers:{"PriceQuotationDetailMove":true}}],attrs:{"title":_vm.$t('PriceQuotationDetails.moveTo')},on:{"click":function($event){return _vm.setPriceQuotationDetailData(priceQuotationDetail)}}},[_c('img',{attrs:{"src":require("@/assets/images/EducationalGroupTransfers.svg")}})])]):_vm._e()])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }